@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    *:focus {
        @apply outline-none;
    }

    @font-face {
        font-family: 'flama-basic';
        src: url('assets/fonts/Flama-Basic.woff');
    }

    @font-face {
        font-family: 'flama-medium';
        src: url('assets/fonts/Flama-Medium.woff');
    }

    .is-mobile {
        @apply portrait:-rotate-90 portrait:origin-top-left portrait:w-[100vh] portrait:h-[100vw] portrait:overflow-x-hidden portrait:absolute portrait:top-full portrait:left-0;
    }

    .create-new-button {
        @apply hover:bg-blue hover:text-black-1 border-2 bg-black-1 text-blue border-blue h-12 text-center align-middle disabled:text-white-1 disabled:border-white-1 disabled:hover:bg-transparent;
    }

    .dialog-body {
        @apply min-h-[2.5rem] min-w-[15rem] flex items-center justify-center;
    }

    .dialog-btn {
        @apply text-white w-2/4 border-transparent px-4 py-3 hover:bg-white-2 focus:outline-none disabled:text-black-8 disabled:bg-transparent hover:disabled:bg-transparent hover:disabled:text-black-8;
    }

    .dialog-btn-primary {
        @apply dialog-btn text-blue hover:bg-blue hover:text-white;
    }
}
